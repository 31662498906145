<template>
  <div class="row dashboard-comercial">
    <div :class="`col-12 col-md-12 mt-2 mb-2`">
      <!-- <boas-vindas class="tabelas-dashboard-comercial" /> -->
      <operacoes class="tabelas-dashboard-comercial" />
    </div>
  </div>
</template>
  <script>
import Operacoes from "./components/OperacoesComerciais.vue";
// import BoasVindas from "./components/BoasVindas.vue";

export default {
  name: "DashboardComercial",
  components: {
    Operacoes,
    // BoasVindas,
  },
  data: () => ({
    title: "DashboardComercial",
    hasComunicados: false,
    isComunicacaoCarregado: false,
    ehComercial: false,
  }),
};
</script>
  <style lang="scss">
.container-dashboard-comercial {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}

.dashboard-comercial {
  .nav-tabs .nav-link {
    background-color: transparent;
    border: none;
    margin: 0px;
    padding-left: 0px;
    color: #bac7d1;
    font-weight: 600;
    font-size: 14px;
  }

  .nav-tabs .nav-link.active {
    color: #343a40;
  }
  .nav-tabs .nav-link:hover,
  .nav-tabs .nav-link:focus {
    border: none;
  }

  .nav-tabs {
    border: none;
  }
}

.tabelas-dashboard-comercial .tabela-valorem.table-responsive {
  height: 380px;
}
</style>
  